$font-weight-light: 200;
$font-weight-normal: 300;
$font-weight-bold: 600;
$badge-font-size: 100%;

$font-family-base: "Noto Sans JP";
$print-page-size: "auto";
$print-body-min-width: 1200px;

@import "../../node_modules/bootstrap/scss/bootstrap";

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Adjustments for fixed navigation and footer */
.default-layout {
  padding-top: 70px;
}

/* Footer */
.footer {
  margin-top: auto;
  background-color: #343a40;
  color: rgba(255, 255, 255, 0.5);
}
.footer .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}
.footer .dropdown-menu {
  min-width: 120px;
}

span.select2.select2-container.select2-container--bootstrap {
  width: 100% !important;
}

.was-validated :valid + .select2 .select2-selection {
  border: 1px solid #28a745;
  border-radius: 4px;
}

.was-validated :invalid + .select2 .select2-selection {
  border: 1px solid #dc3545;
  border-radius: 4px;
}

#serviceWorkerUpdateCntr {
  position: fixed; /* Sit on top of the screen */
  z-index: 9; /* Add a z-index if needed */
  left: calc(50% - 200px); /* Center the snackbar */
  top: 100px; /* 30px from the bottom */
  width: 400px;
}

@include media-breakpoint-down(xl) {
  .img-wrap > a > img {
    width: 199px;
  }
}

@include media-breakpoint-down(lg) {
  .img-wrap > a > img {
    width: 195px;
  }
}

/** Mobile-only */
@include media-breakpoint-down(md) {
  /**
   * Scrolling shadows for bootstrap responsive tables
   * @see https://github.com/iKristjan/bootstrap-responsive-table-scrolling-shadows/blob/master/bootstrap-responsive-table-scrolling-shadows.css
   */
  .table-responsive {
    background:
      radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)) 100% 0;
    background-color: white;
    background-repeat: no-repeat;
    background-size: 10px 100%;
    margin-bottom: 15px;
  }

  .table-responsive > .table {
    background:
      linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(to left, white 30%, rgba(255, 255, 255, 0)) 100% 0;
    background-size: 50px 100%;
    background-repeat: no-repeat;
    max-width: none;
    margin-bottom: 0;
  }

  .custom-btn {
    width: 100%;
    display: block;
  }

  .img-wrap > a > img {
    width: 219px;
  }
}

@include media-breakpoint-down(sm) {
  .img-wrap > a > img {
    width: 100%;
  }
}

.sorting {
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    bottom: 0.3em;
    display: block;
    opacity: 0.3;
  }

  &::before {
    right: 1em;
    content: "↑";
  }

  &::after {
    right: 0.5em;
    content: "↓";
  }

  &.asc::before,
  &.desc::after {
    opacity: 1;
  }
}

.status-outline {
  border: $btn-border-width solid transparent;
  border-radius: $btn-border-radius;

  color: $gray-600;
  background-color: transparent;
  background-image: none;
  border-color: $gray-600;
}

.status-label {
  display: inline-block;
  white-space: nowrap;
  text-align: center;
  user-select: none;
  vertical-align: middle;
  padding: $btn-padding-y $btn-padding-x;
  font-weight: $btn-font-weight;
  font-size: $font-size-base;
  line-height: $btn-line-height;
}

.vertical-label {
  writing-mode: vertical-rl;
  text-orientation: mixed;
}

.progress-overlay {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.status-contract-pending {
  color: color-yiq($purple);
  background-color: $purple;
  border-color: $purple;
}

.status-contract-approved_us {
  color: color-yiq($pink);
  background-color: $pink;
  border-color: $pink;
}

.status-contract-documents_sent {
  color: color-yiq($teal);
  background-color: $teal;
  border-color: $teal;
}

.status-contract-applied {
  color: color-yiq($cyan);
  background-color: $cyan;
  border-color: $cyan;
}

.status-contract-approved_bank {
  color: color-yiq($yellow);
  background-color: $yellow;
  border-color: $yellow;
}

.status-contract-initial_payment_confirmed {
  color: color-yiq($blue);
  background-color: $blue;
  border-color: $blue;
}

.status-contract-keys_sent {
  color: color-yiq($orange);
  background-color: $orange;
  border-color: $orange;
}

.status-contract-active {
  color: color-yiq($green);
  background-color: $green;
  border-color: $green;
}

.status-contract-withdraw_requested {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-contract-withdrawn {
  color: color-yiq($gray-600);
  background-color: $gray-600;
  border-color: $gray-600;
}

.status-contract-cancel_applied {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-contract-billing_stopped {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-contract-cancel_short_settled {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-contract-cancel_settled {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-contract-keys_returned {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-contract-cancelled {
  color: color-yiq($gray-600);
  background-color: $gray-600;
  border-color: $gray-600;
}

.alert-contract-document,
.alert-contract-duration,
.alert-contract-fee_warning {
  color: color-yiq($yellow);
  background-color: $yellow;
  border-color: $yellow;
}

.alert-contract-cancel,
.alert-contract-uncancelled_contract,
.alert-contract-fee_final {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.status-product-contract {
  &-pending {
    color: color-yiq($purple);
    background-color: $purple;
    border-color: $purple;
  }

  &-price_confirmed {
    color: color-yiq($yellow);
    background-color: $yellow;
    border-color: $yellow;
  }

  &-preparing {
    color: color-yiq($orange);
    background-color: $orange;
    border-color: $orange;
  }

  &-active {
    color: color-yiq($green);
    background-color: $green;
    border-color: $green;
  }

  &-withdraw_requested,
  &-cancel_applied,
  &-billing_stopped {
    color: color-yiq($red);
    background-color: $red;
    border-color: $red;
  }

  &-withdrawn,
  &-cancelled {
    color: color-yiq($gray-600);
    background-color: $gray-600;
    border-color: $gray-600;
  }
}

.status-shop-hidden {
  color: color-yiq($gray-600);
  background-color: $gray-600;
  border-color: $gray-600;
}

.status-shop-coming {
  color: color-yiq($yellow);
  background-color: $yellow;
  border-color: $yellow;
}

.status-shop-active {
  color: color-yiq($green);
  background-color: $green;
  border-color: $green;
}

.status-inquiry-new {
  color: color-yiq($yellow);
  background-color: $yellow;
  border-color: $yellow;
}

.status-inquiry-handling {
  color: color-yiq($green);
  background-color: $green;
  border-color: $green;
}

.status-inquiry-handled {
  color: color-yiq($gray-600);
  background-color: $gray-600;
  border-color: $gray-600;
}

.reason-offender,
.blacklist-customer,
.tags-contract {
  color: color-yiq($yellow);
  background-color: $yellow;
  border-color: $yellow;
  margin: 8px 0;
}

.pending-offender {
  color: color-yiq($gray-600);
  background-color: $gray-600;
  border-color: $gray-600;
}

.cleared-offender {
  color: color-yiq($green);
  background-color: $green;
  border-color: $green;
}

.flagged-offender {
  color: color-yiq($red);
  background-color: $red;
  border-color: $red;
}

.exempted-offender {
  color: color-yiq($yellow);
  background-color: $yellow;
  border-color: $yellow;
}

canvas.chart {
  margin: 5px auto;
  display: block;
}

.table-scroll {
  height: calc(100vh - 380px);
  overflow-y: scroll;
}

.bills-table {
  tbody > tr:last-child > td {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(52, 58, 64) !important;
  }

  tfoot > tr:has(+ tr:last-child) > td {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: rgb(52, 58, 64) !important;
  }
}

// Zendesk Bulk Notification
.form-public {
  .form-control {
    background-color: #fff7ed;
  }
}

.border-public {
  border: 1px solid #fed6a8 !important;
  background-color: #fff7ed;
}

.btn-group-toggle {
  .btn-secondary {
    background-color: #b6b6b6 !important;
    border-color: #b6b6b6 !important;

    &:hover {
      background-color: #c9c9c9 !important;
      border-color: #c9c9c9 !important;
    }
  }

  .active {
    background-color: #bb0000 !important;
    border-color: #bb0000 !important;

    &:hover {
      background-color: #b82f2f !important;
      border-color: #b82f2f !important;
    }
  }
}
